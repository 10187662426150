import { PropsWithChildren } from 'react';

const HeaderSuper = (props: PropsWithChildren) => {
  return (
    <div className="font-Simplifica text-8xl tracking-normal text-white sm:text-8xl">
      {props.children}
    </div>
  );
};
const HeaderBody = (props: PropsWithChildren) => {
  return (
    <div className="mt-6 font-sans text-lg leading-8 text-gray-300">
      {props.children}
    </div>
  );
};

const Header = (props: PropsWithChildren) => {
  return (
    <div className="px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
        <div className="mx-auto max-w-full text-center">{props.children}</div>
      </div>
    </div>
  );
};

Header.Super = HeaderSuper;
Header.Body = HeaderBody;

export default Header;
